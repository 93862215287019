import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { IDynamicAnswer, IDynamicConditionQuestion } from '../../../types/IConditionQuestion';
import ValidatedArea from '../../ValidatedArea';
import { isSelected } from '../../../utils/validation';
import IcoRadio from '../../IcoRadio/IcoRadio';
import { CircularProgress } from '@kmx/legos-react-circular-progress';
import AfterMarketModificationAlert from './AfterMarketModificationAlert';

import styles from '../ConditionsBody/ConditionsBody.module.scss';
import { useVehicleInfo } from '../../../context/vehicleInfo';
interface ISingleSelect {
    readOnly: boolean;
    question: IDynamicConditionQuestion;
    conditionValidationTriggered: boolean;
    toggleAnswer: (question: IDynamicConditionQuestion, answerId: number) => void;
    renderQuestions: (questions: IDynamicConditionQuestion[]) => JSX.Element[];
}

const SingleSelect: React.FC<ISingleSelect> = ({
    toggleAnswer,
    question,
    conditionValidationTriggered,
    readOnly,
    renderQuestions,
}) => {
    const [childQuestionsLoading, setChildQuestionsLoading] = useState(null);
    const { vehicleConditionInfo } = useVehicleInfo();
    const { conditionAnswers } = vehicleConditionInfo;

    useEffect(() => {
        const timeout = setTimeout(() => setChildQuestionsLoading(null), 1700);
        return () => {
            clearTimeout(timeout);
        };
    }, [childQuestionsLoading]);

    const choices = question.answers.map((answer: IDynamicAnswer) => {
        // Rendering both a Pill and LEGOS radio button, but one will be hidden based on screen size with CSS
        return (
            <IcoRadio
                key={answer.id}
                id={`${question.id.toString()}-${answer.id.toString()}`}
                name={question.id.toString()}
                value={answer.id.toString()}
                selected={isSelected(question, answer, conditionAnswers)}
                onChange={() => {
                    setChildQuestionsLoading(question.id);
                    toggleAnswer(question, answer.id);
                }}
                label={answer.description}
                disabled={readOnly}
            />
        );
    });

    const detailQuestions = useMemo(
        () =>
            question.answers
                .map((answer: IDynamicAnswer) =>
                    isSelected(question, answer, conditionAnswers) ? renderQuestions(answer.detailQuestions) : null
                )
                .filter(Boolean),
        [question, conditionAnswers, renderQuestions]
    );

    const isInvalid = useMemo(
        () =>
            conditionValidationTriggered &&
            !(conditionAnswers.find(a => a.id === question.id).answers.length > 0),
        [conditionValidationTriggered, conditionAnswers, question.id]
    );

    return (
        <div key={question.id}>
            <fieldset id={'question-' + question.id} className="kmx-flex-wrapper">
                <ValidatedArea
                    overlayIcon={false}
                    invalid={isInvalid}
                    validationMessage="Please make a selection below"
                >
                    <legend>{question.description}</legend>
                </ValidatedArea>
                <div>{choices}</div>
                <AfterMarketModificationAlert question={question} />
            </fieldset>
            <div className={styles.childrenQuestions}>
                {childQuestionsLoading && detailQuestions.length && question.id === childQuestionsLoading ? (
                    <div className={styles.childrenLoading}>
                        <CircularProgress />
                    </div>
                ) : (
                    detailQuestions
                )}
            </div>
        </div>
    );
};

export default SingleSelect;
