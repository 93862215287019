import * as React from 'react';
import { useState, useEffect } from 'react';
import classNames from 'classnames';
import styles from './VehicleProfileSummary.module.scss';
import axios, { AxiosResponse } from 'axios';
import { useVehicleInfo } from '../context/vehicleInfo';

interface IVehicleProfileSummaryProps {
    fullView: boolean;
    children?: React.ReactNode;
}

const VehicleProfileSummary: React.FC<IVehicleProfileSummaryProps> = (props) => {
    const [stockPhotoData, setStockPhotoData] = useState<string>(null);
    const {vehicleInfo, featureInfo, vehicleConditionInfo} = useVehicleInfo();


    useEffect(() => {
        // Make a request for a new stock photo when the year/make/model change
        if (vehicleInfo.profile.year && vehicleInfo.profile.make && vehicleInfo.profile.model) {
            const requestUrl = getStockPhotoUrl(vehicleInfo.profile.year, vehicleInfo.profile.make, vehicleInfo.profile.model);
            axios({
                method: 'get',
                url: requestUrl,
                responseType: 'blob',
            }).then((result: AxiosResponse) => {
                // Check to see that the requested URL matches the final (possibly redirected) response URL. This will catch a redirect to the "coming soon" image.
                if (requestUrl == result.request?.responseURL) {
                    setStockPhotoData(window.URL.createObjectURL(result.data));
                }
                else {
                    // "Coming Soon" redirect
                    setStockPhotoData(null);
                }
            }).catch(() => {
                setStockPhotoData(null);
            });
        }
    }, [vehicleInfo.profile.year, vehicleInfo.profile.make, vehicleInfo.profile.model]);

    const getStockPhotoUrl = (year: string, make: string, model: string) => {
        // Replace whitespace with a dash
        make = make.replace(/\s+/g, '-');

        // Replace ampersand with "and"
        model = model.replace(/&/g, 'and');

        // Replace whitespace with a dash
        model = model.replace(/\s+/g, '-');

        return 'https://img2.carmax.com/assets/mmy-' + encodeURIComponent(make).toLowerCase() + '-' + encodeURIComponent(model).toLowerCase() + '-' + encodeURIComponent(year) + '/image/1.jpg?width=300';
    };

    return (
        <div className={classNames('kmx-ico-vehicle-profile-summary', styles.container, { [styles.fullView]: props.fullView })}>

            {stockPhotoData != null &&
                <div className={styles.imageContainer}>
                    <div className={classNames('kmx-ico-stock-photo', styles.stockPhoto)} style={{ backgroundImage: 'url("' + stockPhotoData + '")' }} />
                </div>
            }

            <div className={classNames('kmx-ico-vehicle-info', styles.vehicleInfo)}>
                {props.fullView && <table className="kmx-table">
                    <tbody>
                        <tr><td><p>Year, Make, and Model</p></td><td><p id="vehicleInfo-YMM" className='kmx-typography--headline-2'>{vehicleInfo.profile.year} {vehicleInfo.profile.make} {vehicleInfo.profile.model}</p></td></tr>
                        {featureInfo.style?.description ? <tr><td><p>Trim</p></td><td><p id="vehicleInfo-Style" className='kmx-typography--headline-2'>{featureInfo.style.description}</p></td></tr> : null}
                        {featureInfo.drive ? <tr><td><p>Drive</p></td><td><p id="vehicleInfo-Drive" className='kmx-typography--headline-2'>{featureInfo.drive}</p></td></tr> : null}
                        {featureInfo.transmission ? <tr><td><p>Transmission</p></td><td><p id="vehicleInfo-Tranmsmission" className='kmx-typography--headline-2'>{featureInfo.transmission}</p></td></tr> : null}
                        <tr><td><p>VIN</p></td><td className={styles.vin_white_space}><p id="vehicleInfo-VIN" className='kmx-typography--headline-2'>{vehicleInfo.vin}</p></td></tr>
                        {vehicleConditionInfo.mileage ? <tr><td><p>Mileage</p></td><td><p id="vehicleInfo-Mileage" className='kmx-typography--headline-2'>{vehicleConditionInfo.mileage}</p></td></tr> : null}
                        <tr><td></td><td></td></tr>
                    </tbody>
                </table>}

                <div className={styles.short}>
                    <div className='kmx-typography--headline-2'>{vehicleInfo.profile.year} {vehicleInfo.profile.make} {vehicleInfo.profile.model}</div>
                    <p className={classNames('kmx-typography--fine-print', styles.vin)}>VIN: {vehicleInfo.vin}</p>
                </div>

            </div>

            {props.children && props.children}

        </div>
    );
};

export default VehicleProfileSummary;
