import * as React from 'react';
import { useState } from 'react';
import ICODialog from './ICODialog';
import TextField from '../../packages/online-appraisals-legos/src/components/TextField';
import KmxButton from '../../packages/online-appraisals-legos/src/components/Button';
import { formatSeperatedInteger } from '../utils/format';
import { useVehicleInfo } from '../context/vehicleInfo';
import { getMileageValidationError } from '../utils/validation';
import { withAnalyticsClickTracking } from '../utils/analytics';
const Button = withAnalyticsClickTracking(KmxButton);

import styles from './OfferRefreshDialog.module.scss';

export interface IOfferRefreshDialogProps {
    closeDialog: () => void;
    setOtherChanges: (otherChanges: boolean) => void;
}

const OfferRefreshDialog: React.FC<IOfferRefreshDialogProps> = ({ closeDialog, setOtherChanges }) => {
    const { vehicleConditionInfo, setVehicleConditionInfo } = useVehicleInfo();
    const [currentStep, setCurrentStep] = useState(0);
    const [cursor, setCursor] = React.useState(null);
    const inputRef = React.useRef(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formatted = formatSeperatedInteger(e.target.value);

        // Comma formatting the input resets the cursor position, we need to maintain it ourselves.
        let cursorPos =  e.target.selectionStart;
        if (formatted !== vehicleConditionInfo.mileage){
            const oldLength = e.target.value.length;
            const newLength = formatted.length;
            cursorPos = newLength - oldLength + e.target.selectionStart;
        }

        setVehicleConditionInfo(prev => ({
            ...prev,
            mileage: formatted
        }));
        setCursor(cursorPos);
    };
    
    React.useEffect(() => {
        if (inputRef.current && cursor != null) {
            inputRef.current.selectionStart = cursor;
            inputRef.current.selectionEnd = cursor;
        }
    }, [vehicleConditionInfo.mileage, cursor]);

    const steps = [
        {
            header: 'Update your mileage',
            body: <div>
                <TextField
                    className={styles.dialogBodyItem}
                    label='Enter mileage'
                    value={formatSeperatedInteger(vehicleConditionInfo.mileage)}
                    onChange={handleChange}
                    validationStatus={getMileageValidationError(vehicleConditionInfo.mileage) ? 'invalid' : 'valid'}
                    helperText={getMileageValidationError(vehicleConditionInfo.mileage)}
                    maxLength={7}
                    inputMode="numeric"
                    ref={inputRef}
                />
                <Button
                    id='offer-refresh-continue'
                    className={styles.dialogBodyItem}
                    level='primary'
                    onClick={() => setCurrentStep(1)}
                    disabled={getMileageValidationError(vehicleConditionInfo.mileage) ? true : false}
                >
                    Continue
                </Button>
                <Button id='offer-refresh-cancel' className={styles.dialogBodyItem} level='tertiary' onClick={closeDialog}>Cancel</Button>
            </div>,
        },
        {
            header: 'Any other changes?',
            body: <div>
                <Button id='offer-refresh-changes' className={styles.dialogBodyItem} level='secondary' onClick={() => setOtherChanges(true)}>Yes</Button>
                <Button id='offer-refresh-no-changes' className={styles.dialogBodyItem} level='secondary' onClick={() => setOtherChanges(false)}>No</Button>
                <Button id='offer-refresh-back' className={styles.dialogBodyItem} level='tertiary' onClick={() => setCurrentStep(0)}>Back</Button>
            </div>,
        }
    ];


    return (
        <ICODialog closeDialog={closeDialog} showDefaultCloseButton={false}>
            <div className={styles.dialogContainer}>
                <h4 className='kmx-typography--display-2'>{steps[currentStep].header}</h4>
                {steps[currentStep].body}
            </div>
        </ICODialog>
    );
};

export default OfferRefreshDialog;
