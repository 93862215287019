import * as React from 'react';
import { useEffect } from 'react';
import { IVehicleInfo } from '../../context/vehicleInfo';
import VehicleProfileSummary from '../VehicleProfileSummary';
import './VehicleBody.module.scss';
import { CircularProgress } from '@kmx/legos-react-circular-progress';
import styles from './VehicleBody.module.scss';
import { useVehicleInfo } from '../../context/vehicleInfo';

interface IVehicleBodyProps {
    onCompleteChange?(isComplete: boolean): void; // Injected by the AccordionStepper
}

const VehicleBody: React.FC<IVehicleBodyProps> = props => {
    const { vehicleInfo } = useVehicleInfo();

    const profileComplete = (vehicleInfo: IVehicleInfo): boolean => {
        // Check all required values for a complete profile
        if (
            vehicleInfo.vin != null &&
            vehicleInfo.profile?.year != null &&
            vehicleInfo.profile?.makeCode != null &&
            vehicleInfo.profile?.make != null &&
            vehicleInfo.profile?.modelCode != null &&
            vehicleInfo.profile?.model != null
        ) {
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        if (profileComplete(vehicleInfo)) {
            props.onCompleteChange(true);
        }
    }, []);

    const resultView = (
        <div id="icoVehicleBody">
            <h3 className="kmx-typography--headline-2">
                Vehicle information
                {!profileComplete(vehicleInfo) && (
                    <CircularProgress size="x-small" className={styles.vehicleLoadingProgress} />
                )}
            </h3>

            {profileComplete(vehicleInfo) && <VehicleProfileSummary fullView={true} />}
        </div>
    );
    return resultView;
};

export default VehicleBody;
