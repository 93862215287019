import { IAppConfig } from "../types/IAppConfig";

//Super simple bare-bones static singleton config, initialized when ICO starts up to save/get config settings
//The purpose of this is so we don't have to use feature testing for optional settings
//This also doesn't need to update on the fly, so no need to use state or anything complicated, just a
//way to store/get some simple initial settings anywhere in the app via singleton. 

//defaults
const storedConfig: IAppConfig = {
    disableScroll: false,
};

export const initializeAppConfig = (appConfig: IAppConfig): void => {
    //merge consumer-defined values into defaults
    Object.assign(storedConfig, appConfig);
};

export const getAppConfig = (): IAppConfig => storedConfig;