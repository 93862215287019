import { request } from './request';
import { AxiosResponse } from 'axios';
import { IDynamicConditionAnswer } from '../types/IConditionQuestion';

export const submitQuoteRequestSSE = (quoteId: string, data: ISubmitData): Promise<AxiosResponse> => {
    return request('post', `quotes/${quoteId}`, null, {
        ...data
    }, false) as Promise<AxiosResponse>;
};

interface ISubmitData {
    ciamId: string;
    vin: string;
    drive: string;
    transmission: string;
    mileage: number;
    metadata: any;
    styleCode: string;
    visitorId: string;
    zipCode: string;
    conditionQuestions: IDynamicConditionAnswer[];
    availableOptions: string[];
    standardOptions: string[];
}
