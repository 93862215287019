/* eslint react/prop-types: 0 */
import * as React from 'react';
import { ReactNode, useState, createContext, useContext, useMemo, useEffect, useCallback } from 'react';
import { getActiveFeaturesV2 } from '../api/featureTesting';
import { killSwitch } from '../utils/offerEvents';

export enum testNames {
    KBB_VALUATION = 'kbb-valuation',
    CONDITION_QUESTION_EMAIL = 'condition-question-email',
    KILL_SWITCH = 'kill-switch',
    PHOTO_CAPTURE = 'photo-capture',
    WALLET_PASS = 'wallet-pass',
    EDIT_VEHICLE_A="edit-vehicle-a",
    EDIT_VEHICLE_B="edit-vehicle-b",
    EDIT_VEHICLE_C="edit-vehicle-c",
    GET_MY_OFFER_ENHANCEMENT_A = 'get-my-offer-enhancements_A',
    GET_MY_OFFER_ENHANCEMENT_B = 'get-my-offer-enhancements_B',
    TRADE_IN_CTA='trade-in-cta',
    SIMPLIFIED_INELIGIBLE_PICSY="simplified-ineligible-picsy",
    DEFAULT_VIN='default-vin',
    MARKET_VALUE_EMAIL_OPT_IN_A="email-opt-in-a",
    MARKET_VALUE_EMAIL_OPT_IN_B="email-opt-in-b",
    MARKET_VALUE_EMAIL_OPT_IN_C="email-opt-in-c",
    MARKET_VALUE_EMAIL_OPT_IN_D="email-opt-in-d",
    REMOVE_OFFER_ASTERISK="remove-offer-asterisk",
    REMOVE_OFFER_ASTERISK_LARGER_TEXT="remove-offer-asterisk-larger-text",
}

export let icoTests = '';

const FeaturesContext = createContext<{
    icoFeatures: string[];
    isFeatureEnabled: (testName: string) => boolean;
    isManualSplitFeatureEnabled: (value: string, enabledValues: string[]) => boolean;
    killswitch: boolean;
}>(null);

interface IFeaturesProviderProps {
    children: ReactNode;
    enabledFeatures: string[];
}

interface ISendTests {
    pendingTests: string[];
    inProcess: boolean;
    featuresInitialized: boolean;
}

const toUnqieArr = (arr: string[]) => Array.from(new Set(arr));

const saveTests = (tests: ISendTests): boolean => {
    const current = icoTests; // icoTests not features since we want the subset
    const updated = [...new Set([...current.split(';'), ...tests.pendingTests])].sort().join(';'); // Merge the current and pending tests

    if (current !== updated) {
        icoTests = updated;
        return true;
    }

    return false;
};

function triggerAdobeTestingView() {
    if (typeof adobeDataLayer != 'undefined' && typeof alloy != 'undefined') {
        alloy('sendEvent', {
            renderDecisions: true,
            xdm: {
                web: {
                    webInteraction: {
                        linkClicks: {
                            values: 1
                        },
                        name: "TargetCall",
                        type: "other",
                    },
                    webPageDetails: {
                        viewName: 'ICO_TESTING',
                    },
                },
            },
        });
    }
    else if (typeof adobe != 'undefined' && adobe.target && typeof adobe.target.triggerView === 'function') {
        adobe.target.triggerView('ICO_TESTING');
    } else {
        setTimeout(triggerAdobeTestingView, 10);
    }
}

const FeaturesProvider: React.FC<IFeaturesProviderProps> = ({ children, enabledFeatures = [] }) => {
    const [icoFeatures, setIcoFeatures] = useState<string[]>(enabledFeatures);
    const [killswitch, setkillswitch] = useState(false);

    // IMPORTANT: Don't put features state in the dependency array
    const setFeatures = useCallback((features: string[], featuresToRemove: string[] = []): void => {
        if (features == null || !Array.isArray(features)) {
            return;
        }

        setIcoFeatures(prev => toUnqieArr([...prev.filter(x => featuresToRemove.includes(x)), ...features]));
    }, []);

    const isFeatureEnabled = useCallback(
        (testName: string): boolean => {
            if (icoFeatures && icoFeatures.length > 0) {
                return icoFeatures.find(x => x === testName) != null;
            } else {
                return false;
            }
        },
        [icoFeatures]
    );

    const isManualSplitFeatureEnabled = useCallback(
        (value: string, enabledValues: string[]) => enabledValues.includes(value.slice(-1).toLowerCase()),
        []
    );

    const sendTests = useCallback(
        (featureUpdateState: ISendTests): void => {
            const modified = saveTests(featureUpdateState);
            featureUpdateState.pendingTests = [];

            if (!modified && featureUpdateState.featuresInitialized) {
                return; // No need to get active features again since nothing changed.
            }

            getActiveFeaturesV2()
                .then(response => {
                    setFeatures(response?.data);
                })
                .finally(() => {
                    if (featureUpdateState.pendingTests.length > 0) {
                        sendTests(featureUpdateState);
                        return;
                    }

                    featureUpdateState.inProcess = false;
                });
        },
        [setFeatures]
    );

    const initializeFeatures = useCallback((features: string[]): Promise<string[]> => {
        return new Promise(resolve => {
            const featureUpdateState: ISendTests = {
                pendingTests: features,
                inProcess: false,
                featuresInitialized: false,
            };

            saveTests(featureUpdateState);
            getActiveFeaturesV2()
                .then(response => {
                    // If it's in the features parameter and the current features state but not in the response then it needs to removed.
                    // This can happen if the enabledFeatures config gets a value that either doesn't exist or is turned off in azure.
                    const featuresNotInResponse = features.filter(x => !(response?.data ?? []).includes(x));
                    setIcoFeatures(prev =>
                        toUnqieArr([...prev, ...response.data].filter(x => !featuresNotInResponse.includes(x)))
                    );
                    resolve(response?.data);
                })
                .catch(err => {
                    console.error('error getting features', err);
                    resolve([]); // continue on with standard experience
                });
        });
    }, []);

    useEffect(() => {
        let icoEventListener: (event: CustomEvent) => void;

        const initializeFeatureTesting = (): void => {
            const featureUpdateState: ISendTests = {
                pendingTests: [],
                inProcess: false,
                featuresInitialized: false,
            };

            icoEventListener = (event: CustomEvent) => {
                if (window?.FS) {
                    window?.FS('trackEvent', {
                        name: 'ICO Feature Enabled',
                        properties: {
                            ico_feature: event?.detail,
                        },
                    });
                }

                featureUpdateState.pendingTests.push(event?.detail);
                if (featureUpdateState.inProcess) {
                    return;
                } else {
                    featureUpdateState.inProcess = true;
                    sendTests(featureUpdateState);
                }
            };

            window.addEventListener('enableICOTest', icoEventListener);
        };

        initializeFeatureTesting();
        triggerAdobeTestingView();

        return () => {
            window.removeEventListener('enableICOTest', icoEventListener);
        };
    }, [sendTests]);

    useEffect(() => {
        setFeatures(enabledFeatures);
    }, [enabledFeatures, setFeatures]);

    // TODO: Not sure if this is needed anymore
    // useEffect(() => {
    //     getActiveFeatures().then(response => {
    //         setFeatures(response?.data);
    //     });
    // }, [setFeatures]);

    useEffect(() => {
        (async function initFeatures() {
            await initializeFeatures(enabledFeatures);
        })();
    }, [enabledFeatures, initializeFeatures]);

    useEffect(() => {
        if (icoFeatures && icoFeatures.length > 0 && icoFeatures.find(x => x === testNames.KILL_SWITCH) != null) {
            killSwitch();
            setkillswitch(true);
        }
    }, [icoFeatures]);

    const value = useMemo(
        () => ({ icoFeatures, isFeatureEnabled, isManualSplitFeatureEnabled, initializeFeatures, killswitch }),
        [icoFeatures, isFeatureEnabled, isManualSplitFeatureEnabled, initializeFeatures, killswitch]
    );

    return <FeaturesContext.Provider value={value}>{children}</FeaturesContext.Provider>;
};

export function useFeatures() {
    return useContext(FeaturesContext);
}

export default FeaturesProvider;
