/* eslint react/prop-types: 0 */
import * as React from 'react';
import { ReactNode, useState, createContext, useContext, useMemo } from 'react';
import { IStoreInfo } from '../types/IStore';

const CustomerInfoContext = createContext(null);

interface ICustomerInfoProviderProps {
    children: ReactNode;
}

export interface ICustomerInfoContext {
    email: string;
    store: IStoreInfo;
}

export const initialState: ICustomerInfoContext = {
    email: '',
    store: null,
};

const CustomerInfoProvider: React.FC<ICustomerInfoProviderProps> = ({ children }) => {
    const [customerInfo, setCustomerInfo] = useState(initialState);
    const [storeInfo, setStoreInfo] = useState<IStoreInfo>(null);

    const values = useMemo(
        () => ({ customerInfo, setCustomerInfo, storeInfo, setStoreInfo }),
        [customerInfo, storeInfo]
    );

    return <CustomerInfoContext.Provider value={values}>{children}</CustomerInfoContext.Provider>;
};

export function useCustomerInfo() {
    return useContext(CustomerInfoContext);
}

export default CustomerInfoProvider;
