import { IProfile } from '../types/IVehicleInfo';
import { vehicleInfoInitialState as initialState } from '../context/vehicleInfo';
import { request } from './request';

interface IDecoderResp {
    vin: string;
    vinCode: string;
    matches: IVinMatch[];
}
interface IVinMatch {
    likelihood: string;
    modelYear: string;
    makeCode: string;
    modelCode: string;
    trimCode: string;
    bodyCode: string;
    engineCode: string;
    modelDescription: string;
    makeDescription: string;
    trimDescription: string;
}

export interface IDecodeResp {
    profile: IProfile;
    vin: string;
}

export const vinDecode = (vin: string): Promise<IDecodeResp> => {
    return request('get', 'decode/vin', { vin }, null, false).then(response => {
        if (response && response.data && response.data.matches && response.data.matches.length > 0) {
            return vinDecodeResultToVehicleInfo(response.data);
        } else {
            return null;
        }
    });
};

export const plateDecode = (licensePlate: string, state: string): Promise<IDecodeResp> => {
    return request('get', 'decode/plate', { state, licensePlate }, null, false).then(response => {
        // TODO: Handle multiple vehicles being returned
        if (response && response.data && response.data.matches && response.data.matches.length > 0) {
            return vinDecodeResultToVehicleInfo(response.data);
        } else {
            return null;
        }
    });
};

const vinDecodeResultToVehicleInfo = (response: IDecoderResp): IDecodeResp => {
    const vehicle: IProfile = { ...initialState.profile };
    let vin = '';

    if (response.matches) {
        if (response.vin) vin = response.vin;
        if (response.matches[0].makeDescription)
            vehicle.make = response.matches[0].makeDescription.split(',')[0].trim();
        if (response.matches[0].modelYear) vehicle.year = response.matches[0].modelYear;
        if (response.matches[0].modelDescription) vehicle.model = response.matches[0].modelDescription.trim();
        if (response.matches[0].trimDescription) vehicle.trim = response.matches[0].trimDescription.trim();
        if (response.matches[0].makeCode) vehicle.makeCode = response.matches[0].makeCode.trim();
        if (response.matches[0].modelCode) vehicle.modelCode = response.matches[0].modelCode.trim();
        if (response.matches[0].modelYear) vehicle.yearCode = response.matches[0].modelYear;
        if (response.matches[0].trimCode) vehicle.trimCode = response.matches[0].trimCode.trim();
    }
    return { profile: vehicle, vin };
};
