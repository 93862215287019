import * as React from 'react';
import styles from './KbbValuation.module.scss';
import { formatDate } from '../../utils/format';
import { useOfferContext } from '../../context/offerContext';

const KbbValuation: React.FC = () => {
    const {offer, kbb} = useOfferContext();
    const iframeSrc = kbb.priceAdvisorUrl;

    return (
        <div className={styles.kbbValuationContainer}>
            <h3 className={styles.compareToKbb}>Compare our offer</h3>
            <div className={styles.kbbWidgetAndDate}>
                <iframe
                    src={iframeSrc}
                    className={styles.kbbWidgetFrame}
                    width={300}
                    height={385}
                    aria-label="KBB Valuation"
                />
                <div className={styles.kbbGenerationDate}>Generated on {formatDate(offer.createdDateUtc, 'mm/dd/yyyy')}</div>
            </div>
        </div>
    );
};

export default KbbValuation;
