import * as React from 'react';
import AnimatedLoaderScreen from '../images/AnimatedLoaderScreen.gif';
import AnimatedLoaderScreenSubmitting from '../images/AnimatedLoaderScreenSubmitting.gif';

import styles from './LoadingScreen.module.scss';

interface ILoadingScreenProps {
    isSubmitting?: boolean;
}

const LoadingScreen: React.FC<ILoadingScreenProps> = ({ isSubmitting }) => {
    const loaderImage = isSubmitting ? AnimatedLoaderScreenSubmitting : AnimatedLoaderScreen;
    return (
        <div className={styles.loadingIndicatorContainer}>
            <img src={`${process.env.BASE_URL}${loaderImage}`} />
        </div>
    );
};

export default LoadingScreen;
