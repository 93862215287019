import { AdditionalConditionQuestionContentType, IConditionQuestion } from './IConditionQuestion';

// export const QUESTION_EDMUNDS_TMV: IConditionQuestion = {
//     questionId: 1010,
//     question: 'What is the overall condition of your vehicle?',
//     subText: 'This will help us provide you with an edmunds.com trade-in value to compare with our offer.',
//     selectedAnswerId: 3,
//     answers: [
//         {
//             questionId: 1010,
//             answerId: 1,
//             answer: 'Outstanding',
//             subText:
//                 'Exceptional mechanical, exterior and interior condition with no visible wear; no reconditioning required.',
//             note: 'Approximately 5% of used vehicles qualify',
//         },
//         {
//             questionId: 1010,
//             answerId: 2,
//             answer: 'Clean',
//             subText:
//                 'Minimal wear and tear with no major mechanical or cosmetic problems; may require limited reconditioning.',
//         },
//         {
//             questionId: 1010,
//             answerId: 3,
//             answer: 'Average',
//             subText:
//                 'Normal wear and tear. May have a few mechanical and/or cosmetic problems and may require some reconditioning.',
//         },
//         {
//             questionId: 1010,
//             answerId: 4,
//             answer: 'Rough',
//             subText: 'Several mechanical and/or cosmetic problems requiring repairs.',
//         },
//         {
//             questionId: 1010,
//             answerId: 5,
//             answer: 'Damaged',
//             subText: 'Major mechanical and/or body damage that may render it in non-safe running condition.',
//         },
//     ],
// };

export const QUESTION_KBB_CONDITION: IConditionQuestion = {
    questionId: 1020,
    question: 'What is the overall condition of your vehicle?',
    subText: 'This will help us provide you with a Kelley Blue Book® Trade-in Range to compare with our offer.',
    selectedAnswerId: 4,
    answers: [
        {
            questionId: 1020,
            answerId: 5,
            answer: 'Excellent',
            subText:
                'Looks new and is in excellent mechanical condition. Has never had any paint or bodywork and does not need reconditioning. The engine is clean and free of leaks.',
        },
        {
            questionId: 1020,
            answerId: 109,
            answer: 'Very good',
            subText:
                'Minor cosmetic defects and is in excellent mechanical condition. Minor or no paint or bodywork, and requires minimal reconditioning. Engine is clean and free of leaks.',
        },
        {
            questionId: 1020,
            answerId: 4,
            answer: 'Good',
            subText:
                'Some cosmetic repairable defects and is free of major mechanical problems. Paint and bodywork may require minor touch-ups. Engine may have minor leaks.',
        },
        {
            questionId: 1020,
            answerId: 3,
            answer: 'Fair',
            subText:
                'Some cosmetic defects that require repairing and/or replacing. Paint and bodywork may require refinishing and repair. Engine has leaks and may require a tune up.',
        },
    ],
    additionalContentType: AdditionalConditionQuestionContentType.KbbCondition,
};
