import * as React from 'react';
import { IProfile } from '../types/IVehicleInfo';
import { Button as KmxButton } from '@kmx/legos-react-button';
import ConditionVerificationImage from '../assets/condition_verification.png';
import styles from './Ineligible.module.scss';
import { ineligibleScheduleAppointment } from '../utils/redirect';
import { useOfferContext } from '../context/offerContext';
import { useFeatures } from '../context/features';
import { withAnalyticsClickTracking } from '../utils/analytics';
const Button = withAnalyticsClickTracking(KmxButton);

interface IIneligibleProps {
    profile: IProfile;
    changeVehicle: () => void;
}

const Ineligible: React.FC<IIneligibleProps> = props => {
    const { ineligibleReason } = useOfferContext();
    const { killswitch } = useFeatures();
    const makeModelMaxLength = 20; // 20 matches homepage

    const prepareVehicleTitle = () => {
        const vehicleTitle = props.profile.make + ' ' + props.profile.model;

        if (props.profile.make && props.profile.model && vehicleTitle.length <= makeModelMaxLength) {
            return vehicleTitle;
        } else if (props.profile.make && props.profile.make.length <= makeModelMaxLength) {
            return props.profile.make;
        } else {
            return 'car';
        }
    };

    const getReason = () => {
        if (killswitch) {
            return "We're working on a fix. Please try again later or schedule an in-person offer at your local CarMax.";
        } else {
            switch (ineligibleReason?.trim()) {
                case 'ymm-ineligible':
                    return 'Your car is pretty rare, and our online offers are based on recent appraisals of similar cars. For your 7-day offer, stop by your local CarMax.';
                case 'offer_exceeds_upper_limit':
                    return 'We handle high-value cars like yours in person. For your 7-day offer, stop by your local CarMax.';
                default:
                    return "Online offers are based on recent appraisals of similar cars, and we haven't seen many like yours lately. For your 7-day offer, stop by your local CarMax";
            }
        }
    };

    return (
        <div id="icoIneligible" className={styles.icoIneligible}>
            <img
                className={styles.image}
                src={process.env.BASE_URL + ConditionVerificationImage}
                aria-describedby="carmax associate verifying condition"
            />
            {!killswitch ? (
                <h3 id="ineligibleMessage" className="kmx-typography--display-3">
                    We&apos;d like to see your {prepareVehicleTitle()}
                </h3>
            ) : (
                <h3 id="ineligibleMessage" className="kmx-typography--display-3">
                    Our online offer service is temporarily down.
                </h3>
            )}
            <p className="kmx-typography--body-1">{getReason()}</p>
            <div className={styles.buttonContainer}>
                <Button
                    id="schedule-offer"
                    className={styles.button}
                    onClick={() => ineligibleScheduleAppointment()}
                    level="primary"
                >
                    Schedule my offer
                </Button>
                {!killswitch && (
                    <Button
                        id="change-vehicle"
                        className={styles.button}
                        onClick={props.changeVehicle}
                        level="tertiary"
                    >
                        Change vehicle
                    </Button>
                )}
            </div>
        </div>
    );
};

export default Ineligible;
