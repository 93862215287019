import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { Select } from '@kmx/legos-react-select';
import { Checkbox } from '@kmx/legos-react-checkbox';
import { Button as KmxButton } from '@kmx/legos-react-button';
import ValidatedArea from '../../ValidatedArea';
import VehicleStyleSelector from './VehicleStyleSelector';
import { useVehicleInfo } from '../../../context/vehicleInfo';
import { AVAILABLE_DRIVES, AVAILABLE_TRANSMISSIONS } from '../FeaturesBody/data';
import useFormFeaturesState from '../../../hooks/useFormFeaturesState';
import { FORM_FIELD_NAMES } from '../../../constants';
import { IVehicleStyleItem, IVehicleStyleDetail } from '../../../types/IVehicleStyle';
import { withAnalyticsClickTracking } from '../../../utils/analytics';
const Button = withAnalyticsClickTracking(KmxButton);

import styles from './FeaturesBody.module.scss';

interface IFeaturesBodyProps {
    onCompleteChange?(isComplete: boolean): void; // Injected by the AccordionStepper
    allValidationTriggered: boolean;
    readonly: boolean;
}

//tossing this here temporarily
interface ISelectOption {
    value: string;
    label: string;
}

const FeaturesBody: React.FC<IFeaturesBodyProps> = ({ onCompleteChange, allValidationTriggered, readonly }) => {
    const { featureInfo, setFeatureInfo, featureLookupInfo, setFeatureLookupInfo } = useVehicleInfo();
    const [standardFeaturesVisible, setStandardFeaturesVisible] = useState(false);
    const [style, setStyle] = useState<IVehicleStyleDetail>(featureInfo.style ? featureLookupInfo.flat().find(y => y.sku.toUpperCase() === featureInfo?.style?.id.toUpperCase()) : null);
    const [offerRefreshStyleGroupSet, setOfferRefreshStyleGroupSet] = useState(featureInfo.style ? true : false);
    const [styleGroup, setStyleGroup] = useState<IVehicleStyleDetail[]>(featureInfo.style ? [featureLookupInfo.flat().find(y => y.sku.toUpperCase() === featureInfo?.style?.id.toUpperCase())] : null);
    const [drive, setDrive] = useFormFeaturesState(featureInfo.drive ?? '');
    const [transmission, setTransmission] = useFormFeaturesState(featureInfo.transmission ?? '');
    const [availableOptions, setAvailableOptions] = useState(featureInfo.style ? featureInfo.availableOptions : []);
    const [standardOptions, setStandardOptions] = useState(featureInfo.style ? featureInfo.standardOptions : []);

    const isComplete = useMemo(() => {
        return (
            styleGroup != null &&
            drive !== null &&
            drive != '' &&
            transmission !== null &&
            transmission != ''
        );
    }, [drive, transmission, styleGroup]);

    // When the available styles change, update step completion as appropriate
    useEffect(() => {
        onCompleteChange(isComplete);
    }, [isComplete, onCompleteChange]);

    useEffect(() => {
        if (style && drive && drive != '' && transmission && transmission != '') {

            setFeatureInfo(prev => ({
                ...prev,
                style: { id: style.sku, description: null },
            }));
        }

        // Save the selected trim & features.
        setFeatureInfo(prev => ({ ...prev, isComplete: isComplete, drive, transmission, availableOptions, standardOptions }));
    }, [drive, transmission, isComplete, setFeatureInfo, setFeatureLookupInfo, availableOptions, standardOptions]);

    const toggleFeature = (options: string[], setter: (a: string[]) => void, featureCode: string) => {
        const foundFeatureCode = options.find(f => f == featureCode);
        setter(foundFeatureCode ? options.filter(f => f != featureCode) : [...options, featureCode]);
    };

    const getFeatureCheckbox = (options: string[], setter: (a: string[]) => void, feature: IVehicleStyleItem) => {
        return (
            <Checkbox
                key={feature.code}
                id={'ico-cb-feature-' + feature.code}
                name={feature.code}
                checked={options.includes(feature.code)}
                onChange={() => toggleFeature(options, setter, feature.code)}
                label={feature.display}
                disabled={readonly}
            />
        );
    };

    const validateData = (data: ISelectOption[], defaultData: ISelectOption[]) => {
        const uniqueList = [...new Map(data.map((item: ISelectOption) => [item["value"], item])).values()]?.filter(x => x.value != null);
        return !uniqueList || uniqueList.length === 0 ? defaultData : uniqueList;
    };

    const getUniqueDriveList = () => {
        const dropdownFormat = styleGroup.map((x: IVehicleStyleDetail) => ({ value: x.drive.code, label: x.drive.display }));
        return validateData(dropdownFormat, AVAILABLE_DRIVES);
    };

    const getUniqueTransmissionList = () => {
        const dropdownFormat = styleGroup.map((x: IVehicleStyleDetail) => ({ value: x.transmission.code, label: x.transmission.display }));
        return validateData(dropdownFormat, AVAILABLE_TRANSMISSIONS);
    };

    useEffect(() => {
        if (styleGroup) {
            //don't update for the first time through during offer refresh
            if (!offerRefreshStyleGroupSet) {
                const drives = getUniqueDriveList();
                const transmissions = getUniqueTransmissionList();
                setDrive(drives.length === 1 ? AVAILABLE_DRIVES.find(x => x.code === drives[0].value)?.value : '');
                setTransmission(transmissions.length === 1 ? AVAILABLE_TRANSMISSIONS.find(x => transmissions[0].label?.includes(x.value))?.value : '');
            }
            //once changed, during offer refresh, then update normally
            else {
                setOfferRefreshStyleGroupSet(false);
            }
        }
    }, [styleGroup]);

    //all answers selected, find and select the correct style sku
    useEffect(() => {
        if (styleGroup && transmission != '' && drive != '') {
            //try to find both matches
            let foundStyle = styleGroup.find((x: IVehicleStyleDetail) => x.drive.display === drive && x.transmission.display?.includes(transmission));
            //try to find 1 out of 2, or default to the first option
            if (!foundStyle) {
                foundStyle = styleGroup.find((x: IVehicleStyleDetail) => x.drive.display === drive || x.transmission.display?.includes(transmission)) || styleGroup[0];
            }
            setStyle(foundStyle);
            setStandardOptions(foundStyle.standardOptions.map(f => f.code));
        }
        else {
            setStyle(null);
            setAvailableOptions([]);
            setStandardOptions([]);
        }
    }, [styleGroup, transmission, drive]);


    useEffect(() => {
        if (style && (style.standardOptions.length === 0 || style.availableOptions.length === 0)) {
            setStandardFeaturesVisible(true);
        }
    }, [style]);

    return (
        <div id="icoFeaturesBody" className={styles.container}>
            <h3 className="kmx-typography--headline-2">
                Style
            </h3>
            <div className={styles.styleWrapper}>
                <ValidatedArea
                    overlayIcon={true}
                    invalid={allValidationTriggered && !styleGroup}
                    validationMessage="Please make a selection above"
                >
                    <VehicleStyleSelector readOnly={readonly} style={style} styleGroup={styleGroup} setStyleGroup={setStyleGroup} offerRefreshStyleGroupSet={offerRefreshStyleGroupSet}/>
                </ValidatedArea>
            </div>
            <div className={styles.driveWrapper}>
                <ValidatedArea
                    overlayIcon={true}
                    invalid={allValidationTriggered && !drive}
                    validationMessage="Please make a selection above"
                >
                    <Select
                        label="Drive"
                        id="ico-features-drive"
                        name={FORM_FIELD_NAMES.vehicleFeatures.drive}
                        options={AVAILABLE_DRIVES}
                        onChange={e => setDrive(e.target.value)}
                        value={drive}
                        disabled={styleGroup == null || readonly}
                    />
                </ValidatedArea>
            </div>
            <div className={styles.transmissionWrapper}>
                <ValidatedArea
                    overlayIcon={true}
                    invalid={allValidationTriggered && !transmission}
                    validationMessage="Please make a selection above"
                >
                    <Select
                        label="Transmission"
                        id="ico-features-transmission"
                        name={FORM_FIELD_NAMES.vehicleFeatures.transmission}
                        options={AVAILABLE_TRANSMISSIONS}
                        onChange={e => setTransmission(e.target.value)}
                        value={transmission}
                        disabled={styleGroup == null || readonly}
                    />
                </ValidatedArea>
            </div>
            {style && transmission != '' &&
                (style.availableOptions.length > 0 ||
                    style.standardOptions.length > 0) && (
                    <div id="icoFeaturesBody-Features" className={styles.features}>
                        <h3 className="kmx-typography--headline-2">Features</h3>
                        {style.availableOptions && style.standardOptions && (
                            <>
                                <fieldset className="kmx-flex-wrapper">
                                    {(style.availableOptions.filter(option => !style.standardOptions.some(val => val.code === option.code))).map((f: IVehicleStyleItem) => getFeatureCheckbox(availableOptions, setAvailableOptions, f))}
                                    {standardFeaturesVisible &&
                                        style.standardOptions.map((f: IVehicleStyleItem) => getFeatureCheckbox(standardOptions, setStandardOptions, f))}
                                </fieldset>
                                {style.standardOptions.length > 0 && <Button
                                    id={standardFeaturesVisible ? "ico-features-hideStandard" : "ico-features-showStandard"}
                                    level="tertiary"
                                    className={styles.showStandardFeaturesButton}
                                    onClick={() => setStandardFeaturesVisible(!standardFeaturesVisible)}
                                >
                                    {`${standardFeaturesVisible ? 'Hide' : 'Show'} standard features`}
                                </Button>}
                            </>
                        )}
                    </div>
                )}
        </div>
    );
};

export default FeaturesBody;
