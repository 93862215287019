import { useCallback, useMemo } from 'react';
import { useVehicleInfo } from '../../context/vehicleInfo';
import { plateDecode, vinDecode } from '../../api/decoder';
import { vehicleDecoded, vehicleDecodeStarted, plateDecodeError } from '../../utils/offerEvents';
import { CLIENT_INELIGIBLE_ERRORS } from '../../constants';
import { IVehicleInfo } from '../../types/IVehicleInfo';
import { IcoStartingMethod } from '../../types/IFormMetadata';
import useTriggerIneligibleEvent from './useTriggerIneligibleEvent';

interface ITriggerDecodeVehicle {
    startingMethod: IcoStartingMethod;
    pageType?: string;
}

export default function useDecodeVehicle() {
    const { vehicleInfo, setVehicleInfo } = useVehicleInfo();
    const { trigger: vehicleIneligible } = useTriggerIneligibleEvent();

    const start = useCallback(
        async ({ startingMethod, pageType }: ITriggerDecodeVehicle): Promise<IVehicleInfo | null> => {
            try {
                vehicleDecodeStarted();
                const resp =
                    startingMethod === 'VIN'
                        ? await vinDecode(vehicleInfo.vin)
                        : await plateDecode(vehicleInfo.plate, vehicleInfo.state);

                if (resp) {
                    vehicleDecoded(resp);
                    const newVehicleInfo = {
                        ...vehicleInfo,
                        vin: resp.vin,
                        profile: resp.profile,
                    };

                    setVehicleInfo(prev => ({ ...prev, ...newVehicleInfo, isComplete: true }));

                    return newVehicleInfo;
                }

                if (startingMethod === 'VIN') {
                    vehicleIneligible({ pageType, reason: CLIENT_INELIGIBLE_ERRORS.errorDecodingVin });
                } else {
                    plateDecodeError(vehicleInfo.plate, vehicleInfo.state, CLIENT_INELIGIBLE_ERRORS.errorDecodingPlate);
                }
            } catch (error) {
                if (error?.response && error.response.status != 401) {
                    vehicleIneligible({ pageType, reason: CLIENT_INELIGIBLE_ERRORS.errorClientSide });
                    console.log(error);
                }
            }

            return null;
        },
        [vehicleIneligible, vehicleInfo]
    );

    return useMemo(() => ({ start }), [start]);
}
