import { IEligibleOffer, IIneligibleOffer } from "../types/SavedResult";
import Cookies from 'js-cookie';

export const eligibleInstantOfferLocalStorageName = 'suycIcoOffer';
export const ineligibleInstantOfferLocalStorageName = 'suycIcoOfferIneligible';
export const eligibleType = 'eligible';
export const ineligibleType = 'ineligible';

export const saveResultToLocalStorage = (type: string, result: IEligibleOffer | IIneligibleOffer): void => {
    resetSavedResult();
    window.localStorage.setItem(type === eligibleType ? eligibleInstantOfferLocalStorageName : ineligibleInstantOfferLocalStorageName, JSON.stringify(result));
    // Cookies.set(type === eligibleType ? eligibleInstantOfferLocalStorageName : ineligibleInstantOfferLocalStorageName, JSON.stringify(result));
};

export const getResultFromLocalStorage = (type: string): IEligibleOffer | IIneligibleOffer => {
    try {
        // let offerJson = Cookies.get(type === eligibleType ? eligibleInstantOfferLocalStorageName : ineligibleInstantOfferLocalStorageName);
        let offerJson = null;
        if (!offerJson) {
            offerJson = window.localStorage.getItem(type === eligibleType ? eligibleInstantOfferLocalStorageName : ineligibleInstantOfferLocalStorageName);
        }
        return offerJson ? JSON.parse(offerJson) : null;
    } catch (ex) {
        console.error('Error retrieving saved offer', ex);
    }
    return null;
};

export const resetSavedResult = (): void => {
    try {
        window.localStorage.removeItem(eligibleInstantOfferLocalStorageName);
        window.localStorage.removeItem(ineligibleInstantOfferLocalStorageName);
        Cookies.remove(eligibleInstantOfferLocalStorageName);
        Cookies.remove(ineligibleInstantOfferLocalStorageName);
    } catch (err) {
        console.error('error resetting ICO state', err);
    }
};

//Disable for non-KMX hosts in prod
export const checkHost = (): boolean => {
    if (process.env.ENV === 'production' && window.location.host === 'www.carmax.com') {
        return true;
    }
    if (process.env.ENV !== 'production') {
        return true;
    }
    return false;
};