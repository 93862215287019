import * as React from 'react';
import { useMemo, useCallback } from 'react';
import { IconInfo } from '@kmx/legos-react-icons';
import classNames from 'classnames';
import { scrollToElement, focusElement } from '../utils/scroll';
import { FORM_FIELD_NAMES } from '../constants';
import { IDynamicConditionQuestion } from '../types/IConditionQuestion';
import { getMileageValidationError, getEmailAddressValidationError, getError } from '../utils/validation';
import { useCustomerInfo } from '../context/customerInfo';
import { useFeatures, testNames } from '../context/features';

import styles from './ValidationSummary.module.scss';
import { useVehicleInfo } from '../context/vehicleInfo';

const getErrorCount = (s: string): number => (s == null || s === '' ? 1 : 0);
const nameQueryBuilder = (s: string): string => `[name='${s}']`;

interface IValidationSummaryProps {
    className: string;
    isConditionStepOpen: boolean;
}

const ValidationSummary: React.FC<IValidationSummaryProps> = props => {
    const { featureInfo, vehicleConditionInfo } = useVehicleInfo();
    const { email } = useCustomerInfo();
    const { conditionAnswers, mileage } = vehicleConditionInfo;
    const { isFeatureEnabled } = useFeatures();

    const featureArr = useMemo(
        () => [
            getErrorCount(featureInfo.style?.id),
            getErrorCount(featureInfo.drive),
            getErrorCount(featureInfo.transmission),
        ],
        [featureInfo]
    );

    const featureErrorCount = useMemo(() => featureArr.reduce((prev, curr) => prev + curr, 0), [featureArr]);

    const errorCount = useMemo(() => {
        if (!featureInfo.isComplete) {
            return featureErrorCount;
        }

        return vehicleConditionInfo.isComplete ? 0 : vehicleConditionInfo.errorCount;
    }, [featureErrorCount, featureInfo, vehicleConditionInfo]);

    const handleOnClick = useCallback(() => {
        if (!featureInfo.isComplete) {
            // Check the vehicle features for errors
            if (featureArr[0]) {
                scrollToElement(nameQueryBuilder(FORM_FIELD_NAMES.vehicleFeatures.style));
            } else if (featureArr[1]) {
                scrollToElement(nameQueryBuilder(FORM_FIELD_NAMES.vehicleFeatures.drive));
            } else if (featureArr[2]) {
                scrollToElement(nameQueryBuilder(FORM_FIELD_NAMES.vehicleFeatures.transmission));
            }
        } else if (!vehicleConditionInfo.isComplete) {
            // Check mileage, email, and condition questions for errors
            const foundQuestion: IDynamicConditionQuestion = getError(vehicleConditionInfo.conditionQuestions, conditionAnswers);
            if (getMileageValidationError(mileage)) {
                const target = nameQueryBuilder(FORM_FIELD_NAMES.conditionsBody.mileage);
                scrollToElement(target);
                focusElement(target, 750);
            } else if (foundQuestion) {
                scrollToElement(`#question-${foundQuestion.id}`);
            } else if (isFeatureEnabled(testNames.CONDITION_QUESTION_EMAIL) && getEmailAddressValidationError(email)) {
                const target = nameQueryBuilder(FORM_FIELD_NAMES.conditionsBody.email);
                scrollToElement(target);
                focusElement(target, 100);
            }
        }
    }, [
        featureArr,
        featureInfo,
        vehicleConditionInfo,
        vehicleConditionInfo.conditionQuestions,
        conditionAnswers,
        mileage,
        email,
        isFeatureEnabled,
    ]);

    // Don't show the errors for the conditions panel if it's not open
    if (errorCount === 0 || (!featureErrorCount && vehicleConditionInfo.errorCount && !props.isConditionStepOpen)) {
        return null;
    }

    return (
        <div className={classNames(props.className)}>
            <div className={styles.validationSummary} onClick={handleOnClick} role="button">
                <IconInfo />
                <div>
                    <h4 className="kmx-typography--headline-2">({errorCount}) Missing fields</h4>
                    <p className="kmx-typography--body-1">Please make a selection above.</p>
                </div>
            </div>
        </div>
    );
};

export default ValidationSummary;
