import * as React from 'react';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import styles from './Tab.module.scss';
import { getTabPanelId } from './TabPanel';
import { withAnalyticsClickTracking } from '../../utils/analytics';
const Button = withAnalyticsClickTracking('button');

interface ITabProps {
    /**
     * Class(es) to add to the form field container
     */
    className?: string;

    /**
     * Boolean attribute indicating whether or not this tab is currently active
     */
    active: boolean;

    /**
     * Boolean attribute indicating if the input should be disabled
     *
     * @default false
     */
    disabled?: boolean;

    /**
     * ID used for the `<input>` and other accessibility concerns
     */
    id?: string;

    /**
     * Attributes to pass to the `<input>` element
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    inputProps?: { [prop: string]: any };

    /**
     * Content for the button
     */
    children: React.ReactNode;

    /**
     * Position of tab. Used for styling the first and last tab
     */
    index?: number;

    /**
     * The number of tabs in a TabBar. Used for styling the last tab
     */
    numTabs?: number;

    /**
     * Value to pass to the `<input>`
     */
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const Tab: React.FC<ITabProps> = props => {
    const {
        className,
        active,
        disabled = false,
        id: initialId,
        inputProps,
        onBlur,
        onClick,
        onFocus,
        children,
        index,
        numTabs,
    } = props;

    const [id] = React.useState(initialId || nanoid(8));
    const buttonId = `button-${id}`;

    return (
        <Button
            className={classNames(className, styles.container, {
                [styles.active]: active,
                [styles.first]: index === 0,
                [styles.last]: index === numTabs - 1,
            })}
            id={buttonId}
            role="tab"
            aria-selected={active ? 'true' : 'false'}
            aria-controls={getTabPanelId(id)}
            type="button"
            disabled={disabled}
            onClick={onClick}
            onBlur={onBlur}
            onFocus={onFocus}
            {...inputProps}
        >
            <label aria-labelledby={buttonId}>{children}</label>
        </Button>
    );
};

export default Tab;
