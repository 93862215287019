import * as React from 'react';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import styles from './TabBar.module.scss';

interface ITabBarProps {
    /**
     * Class(es) to add to the form field container
     */
    className?: string;

    /**
     * ID used for the `<input>` and other accessibility concerns
     */
    id?: string;

    /**
     * Content for the button
     */
    children: React.ReactNode;
}

const TabBar: React.FC<ITabBarProps> = props => {
    const { className, id: initialId, children } = props;

    const [id] = React.useState(initialId || nanoid(8));

    const numTabs = React.Children.toArray(children).length;

    return (
        <div id={id} className={classNames(className, styles.container)} role="tablist">
            {/* iterate over tabs and pass down tab index and number of tabs. Used for styling. */}
            {React.Children.map(children, (child, index) =>
                React.cloneElement(child as React.ReactElement, { index, numTabs })
            )}
        </div>
    );
};

export default TabBar;
