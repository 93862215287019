import * as React from 'react';
import { ReactNode } from 'react';
import Dialog from '../../packages/online-appraisals-legos/src/components/Dialog';
import { Button as KmxButton } from '@kmx/legos-react-button';
import { IconClose } from '@kmx/legos-react-icons';
import styles from './ICODialog.module.scss';
import { withAnalyticsClickTracking } from '../utils/analytics';
const Button = withAnalyticsClickTracking(KmxButton);

const acceptButtonLabel = "got it";

export interface IICODialogProps {
    closeDialog: () => void;
    showDefaultCloseButton: boolean;
    children: ReactNode;
}

const ICODialog: React.FC<IICODialogProps> = ({ closeDialog, showDefaultCloseButton = true, children }) =>
    <Dialog className={styles.icoDialog} acceptAction={closeDialog} acceptButtonLabel={"Close Window"} cancelAction={closeDialog}>
        <Button className={styles.closeButton} level="tertiary" onClick={closeDialog}><IconClose height="24" width="24" /></Button>
        <div className={styles.icoDialogContent}>
            {children}
            {showDefaultCloseButton && <Button level="primary" className={styles.icoDialogBottomCloseButton} onClick={closeDialog}>{acceptButtonLabel}</Button>}
        </div>
    </Dialog>;

export default ICODialog;