export interface IConditionQuestion {
    questionId: number;
    question: string;
    subText?: string;
    answers: Array<IConditionAnswer>;
    selectedAnswerId: number;
    additionalContentType?: AdditionalConditionQuestionContentType;
}

export enum AdditionalConditionQuestionContentType {
    KbbCondition = 1,
}

export interface IConditionAnswer {
    questionId: number;
    answerId: number;
    answer: string;
    subText?: string;
    note?: string;
}

export interface IDynamicConditionQuestion {
    id: number;
    description: string;
    category?: string;
    displayOrder: number;
    answerType: string;
    answers: IDynamicAnswer[];
}

export interface IDynamicAnswer {
    id: number;
    description: string;
    detailQuestions?: IDynamicConditionQuestion[];
}

export interface IDynamicConditionAnswer {
    id: number;
    answers: number[];
}

export interface IDynamicConditionAnswerDetails {
    answerCode: number;
    answerValue: string;
}
