import * as React from 'react';
import { ReactNode, useState, createContext, useContext, useMemo, useEffect } from 'react';
import { useVehicleInfo } from './vehicleInfo';
import { profileComplete } from '../utils/validation';
import { IFormMetadata } from '../types/IFormMetadata';
import { UseState } from '../types/UseStateType';
import { EVENTS } from '../utils/offerEvents';
import * as qs from 'qs';
import { testNames, useFeatures } from './features';
import { getOriginPage } from '../utils/analytics';

const FormContext = createContext<{
    plateDecodeError: boolean;
    setPlateDecodeError: (isPlateValid: boolean) => void;
    plateVinValid: boolean;
    setPlateVinValid: (isPlateValid: boolean) => void;
    startingMethod?: string;
    formMetadata: IFormMetadata;
    setFormMetadata: UseState<IFormMetadata>;
    zipDecodeError: string;
    setZipDecodeError: UseState<string>;
    plateDecodeFailureAlert: boolean;
    setPlateDecodeFailureAlert: UseState<boolean>;
}>(null);

interface IFormProviderProps {
    children: ReactNode;
    startingMethod?: string;
    originPage?: string;
}

const FormProvider: React.FC<IFormProviderProps> = ({ children, startingMethod, originPage }) => {
    const { vehicleInfo } = useVehicleInfo();
    const [plateVinValid, setPlateVinValid] = useState(profileComplete(vehicleInfo));
    const [plateDecodeError, setPlateDecodeError] = useState(false);
    const [plateDecodeFailureAlert, setPlateDecodeFailureAlert] = useState(false);
    const [zipDecodeError, setZipDecodeError] = useState('');
    const { isFeatureEnabled } = useFeatures();
    const marketValueEmailOptInBEnabled = isFeatureEnabled(testNames.MARKET_VALUE_EMAIL_OPT_IN_B);
    const marketValueEmailOptInDEnabled = isFeatureEnabled(testNames.MARKET_VALUE_EMAIL_OPT_IN_D);

    const initialData: IFormMetadata = {
        querystring: {},
        startingMethod: startingMethod === 'LP' || startingMethod === 'VIN' ? startingMethod : undefined,
        marketValueEmailOptIn: marketValueEmailOptInBEnabled || marketValueEmailOptInDEnabled,
    };

    const querystring = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (Object.keys(querystring).length != 0) {
        Object.keys(querystring).forEach(k => {
            if (typeof querystring[k] === 'string') {
                initialData.querystring[k] = querystring[k] as string;
            }
        });
    }

    useEffect(() => {
        const vehicleDecodeEndedHandler = () => {
            setPlateDecodeError(true);
            setPlateDecodeFailureAlert(true);
        };

        const zipCodeDecodeErrorHandler = () => {
            setZipDecodeError('Please enter a valid ZIP code');
        };

        window.addEventListener(EVENTS.plateDecodeError, vehicleDecodeEndedHandler, false);
        window.addEventListener(EVENTS.zipCodeDecodeError, zipCodeDecodeErrorHandler, false);

        return () => {
            window.removeEventListener(EVENTS.plateDecodeError, vehicleDecodeEndedHandler, false);
            window.addEventListener(EVENTS.zipCodeDecodeError, zipCodeDecodeErrorHandler, false);
        };
    }, []);

    const [formMetadata, setFormMetadata] = useState(initialData);

    useEffect(() => {
        setFormMetadata(prev => ({
            ...prev,
            startingMethod: startingMethod === 'LP' || startingMethod === 'VIN' ? startingMethod : undefined,
            marketValueEmailOptIn: marketValueEmailOptInBEnabled || marketValueEmailOptInDEnabled,
        }));
    }, [startingMethod, marketValueEmailOptInBEnabled, marketValueEmailOptInDEnabled]);

    const value = useMemo(
        () => ({
            plateVinValid,
            setPlateVinValid,
            plateDecodeError,
            setPlateDecodeError,
            startingMethod,
            formMetadata,
            setFormMetadata,
            zipDecodeError,
            setZipDecodeError,
            plateDecodeFailureAlert,
            setPlateDecodeFailureAlert,
        }),
        [plateVinValid, plateDecodeError, startingMethod, formMetadata, zipDecodeError, plateDecodeFailureAlert]
    );

    return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};

export function useFormContext() {
    return useContext(FormContext);
}

export default FormProvider;
