import 'custom-event-polyfill';
import './polyfill-nanoid.js';
import 'core-js/stable/symbol/async-iterator';
import 'core-js/stable/symbol/for';
import 'core-js/stable/promise';
import 'core-js/stable/object/assign';
import 'core-js/stable/array/find-index';
import 'core-js/stable/array/from';
import 'core-js/stable/set';
import 'core-js/stable/map';
import 'core-js/stable/function/name';
import 'regenerator-runtime/runtime';
