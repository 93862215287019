import * as React from 'react';
import classNames from 'classnames';
import { IconSuccess, IconRadioButtonUnchecked } from '@kmx/legos-react-icons';
import { CircularProgress } from '@kmx/legos-react-circular-progress';
import styles from './StepHeader.module.scss';
import { withAnalyticsClickTracking } from '../../utils/analytics';
const Button = withAnalyticsClickTracking('button');

export interface IStepHeaderProps {
    isLoading: boolean;
    isDisabled: boolean;
    isExpanded: boolean;
    isComplete: boolean;
    onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
    children: React.ReactNode;
    id?: string;
}

const StepHeader: React.FC<IStepHeaderProps> = props => {
    return (
        <Button
            id={props.id}
            onClick={props.onClick}
            className={classNames(
                'kmx-stepper--step-header',
                styles.stepHeader,
                {
                    [styles.hidden]: props.isExpanded,
                    [styles.complete]: props.isComplete,
                    [styles.disabled]: props.isDisabled,
                },
                'kmx-typography--headline-2'
            )}
        >
            <span>{props.children}</span>
            <span className={styles.completionIcon}>
                {props.isLoading ? (
                    <CircularProgress size="x-small" />
                ) : props.isComplete ? (
                    <IconSuccess />
                ) : (
                    <IconRadioButtonUnchecked />
                )}
            </span>
        </Button>
    );
};

export default StepHeader;
