import { formatVin } from './format';
import { IVehicleInfo } from '../context/vehicleInfo';
import {
    IDynamicAnswer,
    IDynamicConditionQuestion,
    IDynamicConditionAnswer,
    IDynamicConditionAnswerDetails,
} from '../types/IConditionQuestion';
import { mileageToInt } from './format';

export const getEmailAddressValidationError = (email: string): string => {
    const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email == null || email == '') return 'Email address must be entered';
    else if (!emailRegex.test(email)) return 'Please enter a valid email address';
    else return null;
};

export const isValidVin = (value: string): boolean => {
    if (!value || !value.length || value.length != 17) return false;
    value = formatVin(value);
    return getCheckDigit(value) == value.charAt(8);
};

export const profileComplete = (vehicleInfo: IVehicleInfo): boolean => {
    // Check all required values for a complete profile
    if (
        vehicleInfo.vin != null &&
        vehicleInfo.profile?.year != null &&
        vehicleInfo.profile?.makeCode != null &&
        vehicleInfo.profile?.make != null &&
        vehicleInfo.profile?.modelCode != null &&
        vehicleInfo.profile?.model != null
    ) {
        return true;
    } else {
        return false;
    }
};

const getCheckDigit = function (vin: string) {
    // Override for 2015 Jeep Renegade and 2016 Fiat 500X that have check digit problems
    if (vin.substr(0, 5) === 'ZACCJ' || vin.substr(0, 5) === 'ZFBCF') {
        return vin.charAt(8);
    }

    const map = '0123456789X';
    const weights = '8765432X098765432';
    let sum = 0;
    for (let i = 0; i < 17; ++i) {
        sum += transliterate(vin.charAt(i)) * map.indexOf(weights.charAt(i));
    }
    return map.charAt(sum % 11);
};

const transliterate = function (vinChar: string) {
    return '0123456789.ABCDEFGH..JKLMN.P.R..STUVWXYZ'.indexOf(vinChar) % 10;
};

export const isSelected = (
    question: IDynamicConditionQuestion,
    answer: IDynamicAnswer,
    conditionAnswers: IDynamicConditionAnswer[]
) => {
    return (
        conditionAnswers.find(a => a.id === question.id) &&
        conditionAnswers
            .find(a => a.id === question.id)
            .answers.some((details: IDynamicConditionAnswerDetails) => details === answer.id)
    );
};

export const countConditionQuestionErrors = (
    conditionQuestions: IDynamicConditionQuestion[],
    conditionAnswers: IDynamicConditionAnswer[]
) => {
    let count = 0;
    if (conditionQuestions) {
        conditionQuestions.forEach(question => {
            if (
                !(conditionAnswers.find(answer => answer.id === question.id)?.answers.length > 0) &&
                (question.answerType === 'single' || question.id == 310)
            ) {
                count++;
            }
            question.answers.forEach(answer => {
                count += isSelected(question, answer, conditionAnswers)
                    ? countConditionQuestionErrors(answer.detailQuestions, conditionAnswers)
                    : 0;
            });
        });
    }
    return count;
};

export const getMileageValidationError = (mileage: string): string => {
    const intMileage = mileageToInt(mileage);

    if (mileage == null || mileage == '' || isNaN(intMileage)) return 'Mileage must be entered';
    else if (intMileage <= 0) return 'Mileage must be greater than 0';
    else if (intMileage >= 1000000) return 'Mileage must be lower than 1,000,000';
    else return null;
};

// Getting the first error from a list of dynamic questions given the answers that have been provided.
export function getError(conditionQuestions: IDynamicConditionQuestion[], conditionAnswers: IDynamicConditionAnswer[]) {
    let foundQuestion: IDynamicConditionQuestion = null;
    conditionQuestions?.forEach(question => {
        if (foundQuestion) {
            return foundQuestion;
        }

        if (question.answerType === 'single' || question.id == 310) {
            const answer = conditionAnswers.find(answer => answer.id === question.id);
            if (!(answer?.answers.length > 0)) {
                foundQuestion = question;
            }
        }

        question.answers?.forEach(answer => {
            if (isSelected(question, answer, conditionAnswers)) {
                foundQuestion = getError(answer.detailQuestions, conditionAnswers);
            }
        });

        return foundQuestion;
    });
    return foundQuestion;
}
