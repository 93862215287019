import * as React from 'react';
import ConditionVerificationImage from '../../assets/condition_verification.png';
import { Button as KmxButton } from '@kmx/legos-react-button';
import { useVehicleInfo } from '../../context/vehicleInfo';
import { setAppointment } from '../../utils/redirect';
import { useClientInfo } from '../../context/clientInfo';
import { useOfferContext } from '../../context/offerContext';
import { formatSeperatedInteger } from '../../utils/format';
import { withAnalyticsClickTracking } from '../../utils/analytics';
const Button = withAnalyticsClickTracking(KmxButton);

import styles from './ActiveStoreAppraisal.module.scss';

interface IActiveStoreAppraisalProps {
    reset: () => void;
    originPage: string;
}

const ActiveStoreAppraisal: React.FC<IActiveStoreAppraisalProps> = ({ reset, originPage }) => {
    const { vehicleInfo } = useVehicleInfo();
    const { storeAppraisal } = useOfferContext();
    const { clientInfo } = useClientInfo();

    return (
        <div id="icoActiveStoreAppraisal" className={styles.icoActiveStoreAppraisal}>
            <img
                className={styles.image}
                src={process.env.BASE_URL + ConditionVerificationImage}
                aria-describedby="carmax associate verifying condition"
            />
            <h3 className="kmx-typography--display-3">
                It looks like there is already an active appraisal on this vehicle
            </h3>
            <h4 className="kmx-typography--display-2">You&apos;ve got a ${`${formatSeperatedInteger(storeAppraisal.amount)}`} appraisal on your {vehicleInfo.profile.make} {vehicleInfo.profile.model}</h4>
            <p>Offer code: {storeAppraisal.code}</p>
            <p>Valid through {storeAppraisal.expiredTimestampDisplay}</p>
            <div className={styles.buttonContainer}>
                <Button level="primary" className={styles.button} onClick={() => setAppointment(originPage, clientInfo, storeAppraisal)}>Set my appointment</Button>
                <Button level="tertiary" className={styles.button} onClick={reset}>Start with a new car</Button>
            </div>
        </div>
    );
};

export default ActiveStoreAppraisal;
