import { useMemo, useCallback, useEffect, useRef } from 'react';

interface IRetrySSEInterface {
    url: string;
    numberOfRetries?: number;
    retryDelay?: number | ((i: number) => number);
    onSuccess?: (e: MessageEvent) => void;
    onError?: (e: MessageEvent) => void;
    onTimeout?: (e: MessageEvent) => void;
}

/**
 * This hooks allows for an SSE request to be retried multiple times
 * backing off a little each time. Or someone can pass in their own retryDelay
 * function to determine how long the async function should wait before executing.
 * @returns { retry, closeConnection }
 */
export default function useRetrySSERequest() {
    const sourceRef = useRef<EventSource | null>(null);
    const requestTimer = useRef(null);

    const closeConnection = useCallback(() => {
        sourceRef.current?.close();
        clearTimeout(requestTimer.current);
    }, []);

    const retry = useCallback(
        async ({ url, numberOfRetries = 3, retryDelay = 1500, onSuccess, onError, onTimeout }: IRetrySSEInterface) => {
            let attemptCount = 0;
            const connect = () => {
                const handleRetry = () => {
                    attemptCount += 1;
                    requestTimer.current = setTimeout(
                        connect,
                        typeof retryDelay === 'function' ? retryDelay(attemptCount) : retryDelay * attemptCount
                    );
                };

                sourceRef.current = new EventSource(url);
                sourceRef.current.addEventListener('ready', e => {
                    closeConnection();
                    onSuccess?.(e);
                });
                sourceRef.current.addEventListener('timeout', e => {
                    closeConnection();
                    if (attemptCount < numberOfRetries) {
                        handleRetry();
                    } else {
                        onTimeout?.(e);
                    }
                });
                sourceRef.current.onerror = (e: MessageEvent) => {
                    closeConnection();
                    if (attemptCount < numberOfRetries) {
                        handleRetry();
                    } else {
                        onError?.(e);
                    }
                };
            };
            connect();
        },
        []
    );

    useEffect(() => {
        return () => {
            closeConnection();
        };
    }, [closeConnection]);

    return useMemo(() => ({ retry, closeConnection }), [retry, closeConnection]);
}
