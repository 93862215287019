import * as React from 'react';
import styles from './Pill.module.scss';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import SVGCheck from '../../assets/check.svg';

interface IPillRadioProps {
    /**
     * Class(es) to add to the form field container
     */
    className?: string;

    /**
     * Boolean attribute indicating whether or not this radio is currently selected (checked)
     */
    selected: boolean;

    /**
     * Boolean attribute indicating if the input should be disabled
     *
     * @default false
     */
    disabled?: boolean;

    /**
     * ID used for the `<input>` and other accessibility concerns
     */
    id?: string;

    /**
     * Attributes to pass to the `<input>` element
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    inputProps?: { [prop: string]: any };

    /**
     * Caption for the radio
     */
    label: string;

    /**
     * Name of the input
     * Note: should be used to group `<PillRadio/>`'s
     */
    name?: string;

    /**
     * Boolean attribute indicating the `<input>` should be required
     *
     * @default false
     */
    required?: boolean;

    /**
     * Value to pass to the `<input>`
     */
    value?: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const PillRadio: React.FC<IPillRadioProps> = (props) => {
    const {
        className,
        selected,
        disabled = false,
        id: initialId,
        inputProps,
        label,
        name,
        onBlur,
        onChange,
        onFocus,
        required = false,
        value,
    } = props;

    const [id] = React.useState(initialId || nanoid(8));
    const radioId = `radio-${id}`;
    const inputEl = React.useRef(null);

    return (
        <div onClick={() => inputEl.current.click()} className={classNames(className, styles.container, {[styles.selected]: selected, [styles.disabled]: disabled})}>
            <label aria-labelledby={radioId}>
                {label}
            </label>
            {selected && <SVGCheck className={styles.checkIcon} />}
            
            <input
                type="radio"
                ref={inputEl}
                aria-checked={selected}
                id={radioId}
                disabled={disabled}
                checked={selected}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                onFocus={onFocus}
                required={required}
                value={value}
                {...inputProps}
            />
        </div>
            
    );
};

export default PillRadio;
