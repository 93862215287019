import * as React from 'react';
import { ReactNode, useState, createContext, useContext, useMemo, useCallback } from 'react';
import { UseState } from '../types/UseStateType';
import { IOffer } from '../types/IOfferResponse';
import { IKbbValuation } from '../api/kbb';
import { IActiveStoreAppraisal } from '../types/IStartQuote';

const OfferContext = createContext<{
    isIneligible: boolean;
    handleIneligible: boolean;
    offer: IOffer;
    setOffer: UseState<IOffer>;
    storeAppraisal: IActiveStoreAppraisal;
    setStoreAppraisal: UseState<IActiveStoreAppraisal>;
    kbb: IKbbValuation;
    setKbb: UseState<IKbbValuation>;
    ineligible: boolean;
    setIneligible: UseState<boolean>;
    ineligibleReason: string;
    setIneligibleReason: UseState<string>;
    saveResult: boolean;
    quoteId: string;
    setQuoteId: UseState<string>;
    extraQuestions: string[];
    clear: () => void;
}>(null);

interface IOfferContextProviderProps {
    children: ReactNode;
    handleIneligible: boolean;
    saveResult?: boolean;
    extraQuestions?: string[];
}

const OfferContextProvider: React.FC<IOfferContextProviderProps> = ({ children, handleIneligible, saveResult, extraQuestions }) => {
    const [quoteId, setQuoteId] = useState<string>(null);
    const [offer, setOffer] = useState<IOffer>(null);
    const [storeAppraisal, setStoreAppraisal] = useState<IActiveStoreAppraisal>(null);
    const [kbb, setKbb] = useState<IKbbValuation>(null);
    const [ineligible, setIneligible] = useState(false);
    const [ineligibleReason, setIneligibleReason] = useState<string>();

    const isIneligible = useMemo(() => ineligible && handleIneligible, [ineligible, handleIneligible]);

    const clear = useCallback(() => {
        setQuoteId(null);
        setOffer(null);
        setStoreAppraisal(null);
        setKbb(null);
        setIneligible(false);
        setIneligibleReason(null);
        setEstimate(null);
    }, []);

    const values = useMemo(
        () => ({
            isIneligible,
            handleIneligible,
            offer,
            setOffer,
            storeAppraisal,
            setStoreAppraisal,
            kbb,
            setKbb,
            ineligible,
            setIneligible,
            ineligibleReason,
            setIneligibleReason,
            saveResult,
            quoteId,
            setQuoteId,
            extraQuestions,
            clear,
        }),
        [
            quoteId,
            isIneligible,
            handleIneligible,
            offer,
            storeAppraisal,
            kbb,
            ineligible,
            ineligibleReason,
            saveResult,
            extraQuestions,
            clear,
        ]
    );

    return <OfferContext.Provider value={values}>{children}</OfferContext.Provider>;
};

export function useOfferContext() {
    const { handleIneligible, setIneligible, ...context } = useContext(OfferContext);

    return useMemo(() => ({ handleIneligible, setIneligible, ...context }), [handleIneligible, setIneligible, context]);
}

export default OfferContextProvider;
